import platform from 'platform'

let osFamily = platform.os?.family ?? "unknown"

const isIOS = osFamily == "iOS";
const isAndroid = osFamily == "Android";

const considered_mobile = isIOS || isAndroid;

export	{
	considered_mobile, isIOS, isAndroid
		}
