import React from 'react';
import Trytool	from './Trytool';
import './App.css';
import { isIOS, isAndroid } from "./platform.ts";


function App() {
		let iosButton =
				<a href="https://itunes.apple.com/app/apple-store/id1474684190">
					<img id="ios-btn"	src="./images/appbadge/ios.svg"  height="50px"/>
				</a>;

		let androidButton =
			<a href="https://play.google.com/store/apps/details?id=com.portraitai.portraitai">
				<img id="android-btn"	src="./images/appbadge/android.svg" height="80px"/>
			</a>


		let buttons = <div id ="app-ad">
			{isAndroid ? <></> : iosButton }
			{isIOS ? <></>: androidButton}
		</div>



		return (
		<div className="App">
			<img src="images/Header-2.jpg" class="header"/>
			{buttons}
			<Trytool  />
			{buttons}
			<div>
				<span class="privacy-holder">
					<a href="mailto:support@portraitai.app">Contact us</a>
				</span>
				<span class="privacy-holder">
					<a href="https://docs.portraitai.app/privacy">Privacy</a>
				</span>
				<span class="privacy-holder">
					<a href="https://docs.portraitai.app/terms">Terms</a>
				</span>
			</div>
		</div>
		);
	}

export default App;
