import React	from 'react';
import Dropzone	from './Dropzone';

function Trytool() {
	let urlParams = new URLSearchParams(window.location.search);
	let maybeId = urlParams.get("img")
	return (
		<div id="trytool">
			<div className="Card">
				<Dropzone onFilesAdded={console.log} disabled={false} initial_id={maybeId}/>
			</div>
		</div>
	);
}

export default Trytool;
