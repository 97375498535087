import React, { useState, useEffect, Component } from "react";

import { considered_mobile } from "./platform";

import { ReactComponent as Twitter_logo } from "./svg/t.svg";
import { ReactComponent as FB_logo } from "./svg/f.svg";
import { ReactComponent as Pin_logo } from "./svg/pin.svg";

import {upload, UploadSuccess} from './ServerAPI';

import { FacebookShareButton, TwitterShareButton, PinterestShareButton} from 'react-share'

import Uri from 'jsuri'


function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Loader() {
  return (
    <div className="loader">
      <div className="loader-inner">
        <div className="loader-line-wrap">
          <div className="loader-line"></div>
        </div>
        <div className="loader-line-wrap">
          <div className="loader-line"></div>
        </div>
        <div className="loader-line-wrap">
          <div className="loader-line"></div>
        </div>
        <div className="loader-line-wrap">
          <div className="loader-line"></div>
        </div>
        <div className="loader-line-wrap">
          <div className="loader-line"></div>
        </div>
      </div>
    </div>
  );
}

function img_uploaded() {
  var el;
  el = document.querySelectorAll(".my_photo");
  el = el[el.length - 1];
  el.scrollIntoView();
}

String.prototype.hashCode = function () {
  var hash = 0;
  if (this.length == 0) {
    return hash;
  }
  for (var i = 0; i < this.length; i++) {
    var char = this.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

var drop_zone_component: Dropzone | null = null;

var hashCodes_of_loaded = [];

function img_loaded() {
  var el;
  el = document.querySelectorAll(".processed");
  el = el[el.length - 1];
  // el.style.display	=	'initial'
  try {
    console.log(Number.parseInt(el.getAttribute("data-hashcode")), "hashcode");
    hashCodes_of_loaded.push(Number.parseInt(el.getAttribute("data-hashcode")));
  } catch (e) {}
  if (processing_state == 2) {
    processing_state = 1;
  }
  drop_zone_component.setState({ last_action: "loaded processed img" });
  window.requestAnimationFrame(() => {
    el.scrollIntoView();
  });
}

function ShareButtons(props: { url: string, img_path: string }) {
  let addr = props.url
  let path = props.img_path
  
  return (
    <>
      {/* <a href={props.url}>MY BUTTON</a> */}
      <FacebookShareButton url={addr} hashtag="PortraitAI" quote="Want to see yours too, please #PortraitAI">
        <FB_logo />
      </FacebookShareButton>
    

      <TwitterShareButton url={addr} hashtags={["PortraitAI"]} title="Want to see yours too, please #PortraitAI">
        <Twitter_logo/>
      </TwitterShareButton>

      <PinterestShareButton url={addr} media={path} title="Want to see yours too, please #PortraitAI">
        <Pin_logo />
      </PinterestShareButton>
      
    </>
  );
}

function Photos(props: { results: string[]; sources: string[] }) {
  let myPhoto = (
    <div>
      {props.sources.map((path, i) => (
        <div key={"photo" + i}>
          <img className="my_photo" src={path} onLoad={img_uploaded} />
        </div>
      ))}
    </div>
  );
  return (
    <>
      {myPhoto}
      <div>
        {props.results.map((id) => {
          let addr = "https://portraitplus.facefun.ai/" + id + "-_port.jpg"
          let url = new Uri(window.location.href)
                .setQuery("img="+id);
          let hc = id.hashCode();
          return (
            <div
              key={hc}
              className="processed-div"
              style={
                hashCodes_of_loaded.includes(hc) ? {} : { display: "none" }
              }
            >
              <img
                className="processed"
                src={addr}
                onLoad={img_loaded}
                data-hashcode={hc}
              />
              <br />
              <ShareButtons url={url.toString()} img_path={addr}/>
            </div>
          );
        })}
      </div>
    </>
  );
}

// export	{
// 			// React.memo(Ad)	as	default
// 			Photos
// 		}

// var last_alert: JSX.Element | null = null;


enum ProcessingState {
  PARTIALLY_FINISHED = 0,
  DO_NOTHING = 1,
  FINISHED = 2,
  IN_PROGRESS = 3,
}

var processing_state = ProcessingState.DO_NOTHING;


type DropzoneProps = {
  onFilesAdded: (_: any) => any,
  disabled: boolean,
  initial_id?: string
}

type DropzoneState = {
  highlight: boolean,
  source_images: string[],
  resulting_ids: string[],
  last_action: string,
  alert: JSX.Element,
  counter: number
}

function scroll_into_dropzone() {
  var el  = document.getElementById("Dropzone");
  if (el) {
    window.requestAnimationFrame(() => {
      el.scrollIntoView();
    });
  }
}

class Dropzone extends Component<DropzoneProps, DropzoneState> {
  fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: DropzoneProps) {
    super(props);

    let inital_ids = props.initial_id ? [props.initial_id] : [];
    this.state = {
      highlight: false,
      source_images: [],
      resulting_ids: inital_ids,
      last_action: "none",
      alert: <></>,
      counter: considered_mobile ? 3 : 3,
    };

    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.upload = this.upload.bind(this);
    this.fileListToArray = this.fileListToArray.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  componentDidMount() {
    drop_zone_component = this;
  }

  upload(file, times: number, with_face: boolean = true) {

    upload(file, with_face, times < 2)
    .then((result) => {
        if (result instanceof UploadSuccess) {
          processing_state = times > 0 ? ProcessingState.IN_PROGRESS : ProcessingState.FINISHED;
          this.setState({
            resulting_ids: [
              ...this.state.resulting_ids,
              result.id
            ],
            last_action: "+ result",
          });

          if (times > 0) {
            this.upload(file, times-1, with_face);
          }

        } else {
          if (result.error.includes("face") && with_face) {
            this.upload(file, times, false);
            return;
          }
            
          let last_alert = (
              <>
                <>
                  <br />
                  Sorry!
                  <br />
                  Something went wrong {result.error} {typeof result}
                  <br />
                  Maybe, try a different photo?
                </>
              </>
            );
            processing_state = ProcessingState.DO_NOTHING;
            this.setState({
              last_action: "failed",
              alert: last_alert
            });
            scroll_into_dropzone();
        }
    })
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.props.disabled) return;
    this.setState({
      highlight: true,
      last_action: "+ highlight",
    });
  }

  onDragLeave() {
    this.setState({
      highlight: false,
      last_action: "- highlight",
    });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }

    this.setState({
      highlight: false,
      last_action: "- highlight",
    });
  }

  fileListToArray(list) {
    console.log(this.state.source_images);
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));

      var reader = new FileReader();
      var self = this;
      reader.onload = function (e) {
        processing_state = ProcessingState.PARTIALLY_FINISHED;
        self.setState({
          source_images: [e.target.result as string],
          resulting_ids: [],
          last_action: "+ source",
          counter: self.state.counter - 1
        });
      };
      reader.readAsDataURL(list.item(i));

      this.upload(list.item(i), 5);
    }
    return array;
  }

  render() {
    var select_pic = (
      <div>
        Pick a Selfie pic
        <br />
        <i id="dnd">(Drag & Drop or Click here)</i>
      </div>
    );
    if (considered_mobile) select_pic = <>Upload a Selfie</>;

    let appStoreLine = <></>
   // if (this.state.counter <= 0) {
      appStoreLine = <><b>Download app</b> to get 100+ new filters. Cartoon, Portraits HD, Halloween, Child etc. </>
   // }

    let dropbox = <div
          id="Dropzone"
          className={
            "Dropzone " +
            (this.state.highlight ? "Highlight " : "") +
            (considered_mobile ? "mobile_dropzone " : "")
          }
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? "default" : "pointer" }}
        >
          <input
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            onChange={this.onFilesAdded}
          />
          <img
            alt="upload"
            className="Icon"
            src={
              considered_mobile
                ? "./images/portrait-white-48dp.svg"
                : "./images/portrait.svg"
            }
          />
          <span>{select_pic}</span>
          <span>{this.state.alert}</span>
        </div>

    let footer = <></>

    if (processing_state == ProcessingState.IN_PROGRESS || processing_state == ProcessingState.PARTIALLY_FINISHED) {
      footer =  <>
        <></>
        Painting
        <br />
        <br />
        <Loader />
      </>
    } else if (processing_state == ProcessingState.DO_NOTHING) {
      if (this.state.source_images.length == 0) {
        footer = <div id="above-trytool-note">
            <strong>Upload selfie to get your 18th century portrait painted by AI. </strong>
            <br />
            To get the best result, please upload passport-like photo. No glasses.
            Large face area.
            <br />
            <b>Download app</b> with 100+ new filters (Cartoon, Portrait HD, Halloween etc.).
            <></>
          </div>
      }

      if (this.state.counter > 0) {
        footer = <>
          {footer}
          {dropbox}
          {appStoreLine}
        </>
      } else {
        footer = <>
          {footer}
          {appStoreLine}
        </>
      }
    }

    let textTop = <></>
    if (this.state.source_images.length != 0) {
      textTop = appStoreLine
    }

    return (
      <div id="img_upload">
        {textTop}
        
        <Photos
          sources={this.state.source_images}
          results={this.state.resulting_ids}
        />

       {footer}
      </div>
    );
  }
}

export default Dropzone;
