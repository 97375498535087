function makeid(length: Number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

class UploadSuccess {
  private _id: string;

  get id() {
      return this._id;
  }

  constructor(id: string) {
    this._id = id;
  }
}

class UploadError {
    private _error: string;
  
    get error() {
        return this._error;
    }
  
    constructor(error: string) {
      this._error = error;
    }
  }

class Test {
  private test: Array<Number>;
}

async function upload(file: File, with_face: boolean, collage: boolean): Promise<UploadSuccess | UploadError> {
  const formData = new FormData();
  formData.append("id", "11111-" + makeid(6));
  formData.append("image", file);
  formData.append("wm", "3");

  if (Math.random() < 0.5) {
    formData.append("type", "1");
  } else {
    formData.append("type", "0");
  }

  if (collage) {
    formData.append("collage", "1");
  }

  if (with_face === false) {
    formData.append("no_crop", "1")
  }

  // let ask = await fetch("https://portraitplus.facefun.ai/Port/MakePort", {
  //   method: "POST",
  //   body: formData, // This is your file object
  // });


  let text = await fetch("https://portraitplus.facefun.ai/Port/MakePort", {
    method: "POST",
    body: formData, // This is your file object
  })
    .then((response) => {
        let ans = response.text();
        return ans;
    })
    .catch((error) => "Connection error");

  if (text.endsWith(".jpg")) {
    return new UploadSuccess(
      text.substring(0, 12)
    )
  } else {
    return new UploadError(text)
  }
}

export { upload, UploadSuccess, UploadError };
